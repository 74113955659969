/* eslint-disable no-undef */
import { Route, route, Router } from "preact-router";
import RootRoute from "./routes/RootRoute";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import NotfoundScene from "./screens/NotfoundScene";
import AccountRoute from "./routes/AccountRoute";
import UserMobx from "./mobx/UserMobx";
import { env } from "./config";

if (env.node !== "development") {
  //console.log = () => null;
  //console.error = () => null;
}

const App = () => {
  window.document.title = "ระบบล็อคอินกลาง";
  function handleRouter(e) {
    const allowed = UserMobx.onCheckLogin;
    if (!allowed) {
      if (e.url.indexOf("account") >= 0) {
        return route(e.url);
      } else {
        return route("/account/login");
      }
    } else {
      if (e.url.indexOf("/account/") >= 0) {
        return route(e.url);
      }
      return route(["/"].includes(e.url) ? "/main" : e.url);
    }
  }

  return (
    <div>
      <Router onChange={(e) => handleRouter(e)}>
        <AccountRoute path="/account/:rest*" />
        <AuthenticatedRoute path="/:rest*" login="/login" route={RootRoute} />
        <Route path="*" default component={NotfoundScene} />
      </Router>
    </div>
  );
};

export default App;
