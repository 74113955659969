import cryptr from 'crypto-js'
import { env } from "../config";

export const validateEmailOrganization = (email = '') => {
    if (!email) {
        return false
    }

    const emailDomain = (email.match(/@dplusonline.net|@zettasoft.co.th|@fininsurance.co.th/) || []);
    return emailDomain.length > 0
}

export const encryptParameter = (object) => {
    return cryptr.AES.encrypt(JSON.stringify(object), env.key_hash).toString();
}

export const decryptParameter = (encrypt) => {
    return cryptr.AES.decrypt(encrypt, env.key_hash).toString(cryptr.enc.Utf8);
}