import { Component } from 'preact'

class NotfoundScene extends Component {

  render() {
    return (
      <div style={{ marginTop: '100px', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{ textAlign: 'center' }}>404 Not found</h1>
      </div>
    )
  }
}

export default NotfoundScene
