import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined,
  LoginOutlined,
  ClearOutlined,
  LockOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
  SaveOutlined,
  FileSearchOutlined,
  ReloadOutlined,
  InfoCircleOutlined,
  UserAddOutlined,
  ShakeOutlined,
  FileAddOutlined,
  PrinterOutlined,
  CheckCircleOutlined,
  CopyrightOutlined,
  QrcodeOutlined,
  ExclamationCircleOutlined,
  InteractionOutlined,
  //ToolOutlined,
  //FieldTimeOutlined,
  //LaptopOutlined,
  //SolutionOutlined,
  //DollarOutlined,
  //CaretRightOutlined,
  FileOutlined,
  RedoOutlined,
  UnlockOutlined,
  //ContactsOutlined,
} from "@ant-design/icons";
import * as AntDIcon from "@ant-design/icons";

export const Icon = {
  customerList: () => <TeamOutlined />,
  report: () => <FileSearchOutlined />,
  manageCredit: () => <CopyrightOutlined />,
  createQRCode: () => <QrcodeOutlined />,

  /**most for button */
  search: () => <SearchOutlined />,
  edit: () => <EditOutlined />,
  remove: () => <DeleteOutlined />,
  clear: () => <ClearOutlined />,
  upload: () => <UploadOutlined />,
  download: () => <DownloadOutlined />,
  login: () => <LoginOutlined />,
  forgetPassword: () => <LockOutlined />,
  create: () => <PlusCircleOutlined />,
  back: () => <RollbackOutlined />,
  save: () => <SaveOutlined />,
  confirm: () => <CheckCircleOutlined />,
  reAct: () => <ReloadOutlined />,
  recheck: () => <InfoCircleOutlined />,
  register: () => <UserAddOutlined />,
  manageAppReport: () => <ShakeOutlined />,
  manageUserReport: () => <TeamOutlined />,
  manageReport: () => <FileAddOutlined />,
  print: () => <PrinterOutlined />,
  warningAlert: () => <ExclamationCircleOutlined />,
  systemFlow: () => <InteractionOutlined />,
  RedoOutlined: () => <RedoOutlined />,
  UnlockOutlined: () => <UnlockOutlined />,
};

export const IconMenu = ({ iconName, iconType, iconD, viewBox }) => {
  // if (!iconName || iconName == "" || !iconType || iconType == "")
  //   return <FileOutlined />;

  if (iconType == "AntD") {
    const ComponentIcon = AntDIcon[iconName];
    return <ComponentIcon />;
  }

  if (iconType == "custom" && iconD) {
    const pathArr = iconD.split(",");
    const ComponentIcon = () => (
      <span
        role="img"
        aria-label="field-time"
        class="anticon anticon-field-time ant-menu-item-icon"
      >
        <svg width="1em" height="1em" fill="currentColor" viewBox={viewBox}>
          {pathArr.map((e) => (
            <path d={e} />
          ))}
        </svg>
      </span>
    );
    return <ComponentIcon />;
  }

  return <FileOutlined />;
};
