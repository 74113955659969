import { GET_MENU_LINK, POST } from "../services";
import { message } from "antd";
import { route } from "preact-router";

export const goToPageAuth = async (webID, isRedirect) => {
  try {
    const res = await POST(GET_MENU_LINK, { ref: webID });
    if (!res || !res.success) {
      return;
    }
    const { url, externalLink } = res.result;
    if (isRedirect) {
      window.location = url;
    }
    if (externalLink == 1) {
      setTimeout(() => {
        const windowReference = window.open();
        windowReference.location = url;
      });
    } else {
      route(url);
    }
  } catch (error) {
    console.log(error);
    if (error && error.message) {
      message.error(error.message);
    }
  }
};
