import { create } from 'apisauce';
import { env } from '../config';
import UserMobx from '../mobx/UserMobx';
export const ip = 'http://192.168.1.169:2000';

export const MODE_API = {
  DEVELOPMENT: env.url_dev,
  UAT: env.url_uat,
  PRODUCTION: env.url_prd,
}


export const CONNECT_API = MODE_API[`${env.node}`.toUpperCase()]
const api = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 10000 // 10 seconds
})

const apiupload = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
  }
})

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path 
 * @param {{}} obj 
 * @return {{}}
 */

export const POST = (path, obj, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    api.post(path, obj, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        if (response.ok) {
          resolve(response.data)
        } else {
          response.data ? reject(response.data) : reject({ success: false, message: response.problem })
        }
      }).catch(err => reject(err))
  })

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path 
 * 
 */

export const GET = (path, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    api.get(path, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        if (response.ok) {
          resolve(response.data)
        } else {
          response.data ? reject(response.data) : reject({ success: false, message: response.problem })
        }
      }).catch(err => reject(err))
  })

export const UPLOAD = (path, formdata, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    apiupload.post(path, formdata, {
      headers: {
        Authorization: token,
      }
    })
      .then(response => {
        if (response.ok) {
          resolve(response.data)
        } else {
          response.data ? reject(response.data) : reject({ success: false, message: response.problem })
        }
      }).catch(err => reject(err))
  })

/* ################################################## URL ################################################## */
export const LOGIN = `/authen/login`;
export const GET_PROFILE = `/authen/me`
export const GET_MENU = `/menu/get`
export const GET_MENU_LINK = `/menu/get-link`
export const LOGIN_WEB = `/authen/login-web`
export const FORGET_PASSWORD = `/authen/forgot-password`
export const FORGET_PASSWORD_CHANGE = `/authen/reset-password`
export const PASSWORD_CHANGE = `/authen/change-password`
export const GET_ALL_USER = `/permission/get-users`
export const GET_PEMISSION = `/permission/get-permission`
export const RESET_PASSWORD = `/permission/reset-password`
export const DELETE_PERMISSION = `/permission/delete-permission`
export const GET_ROLE_NAME = `/permission/get-role-name`
export const UPDATE_PERMISSION = `permission/update-permission`
export const GET_ALL_WEB = `/permission/get-web-name`
export const INSERT_PERMISSION = `/permission/create-permission`
