export const VERSION_WEB = {
  dev: 'version 0.0.1 21-06-2021 | 16:01',
  prd: 'version 0.0.1 18-02-2021 | 13:50'
}

export const default_form_input = [{
  form_id: 1,
  label: 'ชื่อ-นามสกุล',
  ref: 'fullname',
  type: 'INPUT',
  style: {
    marginRight: 50
  },
  customerProps: {
    maxLength: 20
  },
  className: 'customer-input'
}, {
  form_id: 1,
  label: 'เบอร์โทร',
  ref: 'phone_number',
  type: 'INPUT'
}]

export const KEY_STORAGE = 'SzW8ihVNuDgTZGpenN2Rve8GbLJqtW8T'
export const KEY_HASH = 'KeIYgt6Dr4AKwMlvzoF91el6DfG1cnma';